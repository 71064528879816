/* eslint-disable */
/* eslint-disable */

import Vuex from 'vuex';
//import libv from "../scripts/libvorbis"
import axios from 'axios';
import router from '@/router'
const apiURL =document.location.host.indexOf('test')==-1&&document.location.host.indexOf('localhost')==-1?process.env.VUE_APP_SERVER_URL:process.env.VUE_APP_TESTSERVER_URL
export default new Vuex.Store({
  state: {
    apiURL : apiURL,
    snackbar:{
      show:false,
      text:'',
      color:''
    },
    status: '',
    token: localStorage.getItem('token') || null,
    userID:localStorage.getItem('userID') ||null,
    role:localStorage.getItem('role') ||null,
    user : {},
    located:'ru',
    scripts:[],
    currentDialogID: null,
    script:{
      id:null,
      newDialogID:null,
      lastDialogID:null,
      name:"",
      desc:"",
      createDate:"",
      filteredPlainText:[],
      dialogs:[
          {
          id:1,
          attributes:{
            answers:[],
            dialog:"",
            dialog_id:null
          }
        }
      ],
      plainText:[
        {
          type:"",
          id:null,
          text:"",
          dID:null,
          nextDialogId : null,
          elemID : null
        }
      ],
      dialogsOnly:[
        {
          type:"",
          id:null,
          text:"",
          dID:null,
          nextDialogId : null,
          elemID : null
        }
      ],
      searchIDs:[],
    }
  },
  getters : {
    getRole: state=> state.role,
    isLoggedIn: state => !!state.token,
    getLocated:state=>state.located,
    snackbar:state =>state.snackbar,
    token: state => state.token,
    getUserID: state=> state.userID,
    authStatus: state => state.status,
    scripts: state => state.scripts,
    script: state => state.script,
    scriptTypes:state=>state.scriptTypes,
    filteredPlainText: state => state.script.filteredPlainText,
    plainText: state => state.script.plainText,
    dialogsOnly: state => state.script.dialogsOnly,
    searchIDs: state => state.script.searchIDs,
    mapKey: state => state.mapKey,
    apiURL : state => state.apiURL,
  },
  mutations: {
    auth_request(state){
      state.status = 'loading'
    },
    setRole(state,data){
      localStorage.setItem('role', data)
      state.role=data
    },
    setSnackbar(state,{data,color}){
      state.snackbar.show = true
      state.snackbar.text = data
      state.snackbar.color = color
    },
    setScriptTypes(state,data){
      state.scriptTypes=data
    },
    setDialogID(state,id){
      
      state.script.lastDialogID=id
    },
    setScripts(state,data){
      state.scripts=data
      state.script.lastDialogID=null
      //state.currentDialogID = data[0].currentDialogID.id;
    },
    setScript(state,data){
      state.script.id=data.id
      state.script.name=data.attributes.name
      state.script.desc=data.attributes.description
      state.script.createDate=data.attributes.createDate
    },
    setSearchIDs(state,data){
      
      state.script.searchIDs=data
    },
    setfilteredPlainText(state,data){
      state.script.filteredPlainText=data
    },
    setDialogs(state,data){
      
     // state.script.lastDialogID=null;
      state.script.dialogs=data
      state.mapKey++

      if(state.script.dialogs.length&&state.script.lastDialogID==null){
       
        if(state.script.newDialogID==null){
        state.script.lastDialogID=state.script.dialogs[0].id
       }
       else{
        state.script.lastDialogID=state.script.newDialogID
        state.script.newDialogID=null
       } 
       
      
      }
      const dl = data.length;
      let plainText = [];
      let dialogsOnly = [];
      for(let _d = 0; _d < dl; _d++) {
        let dID = data[_d].id
        plainText.push(
          {type : "d", 
          id : data[_d].id, 
          text : data[_d].attributes.dialog, 
          dID : dID,
          nextDialogId : null,
          elemID : data[_d].id
        });
        dialogsOnly.push(
          {type : "d", 
          id : data[_d].id, 
          text : data[_d].attributes.dialog, 
          dID : dID,
          nextDialogId : null,
          elemID : data[_d].attributes.dialog_id
        });
        let al = data[_d].attributes.answers.length;
        for(let _a = 0; _a < al; _a++) {
          plainText.push(
            {type : "a", 
            id : data[_d].attributes.answers[_a].id, 
            text : data[_d].attributes.answers[_a].answer, 
            dID : dID,
            nextDialogId : data[_d].attributes.answers[_a].next_dialog_id,
            elemID : data[_d].attributes.answers[_a].answer_id
          });
        }
      }
      state.script.plainText=plainText
      state.script.filteredPlainText=plainText
      state.script.dialogsOnly=dialogsOnly

    },
    setDialog(state,data){
      state.script.newDialogID=data.id
      state.script.lastDialogID=null
    },
    initSuccess(state, data){
      state.status = 'success'
      state.token=data.token
      state.located=data.locale
      state.userID=data.c
    },
    
    auth_success(state, data){
      state.status = 'success'
      state.token = 'Bearer '+data.jwt
      state.user = data.user

    },
    auth_error(state){
      state.status = 'error'
    },
    set_token(state, data){
      state.status = 'success'
      state.token = data
    },
    removeToken(state){
      state.token = ''
      delete axios.defaults.headers.common['Authorization']
    },
    logout(state){
      state.status = ''
      state.token = ''
    },
    searchResultPositions(state,data){
      state.searchResultPositions = data;
    },
    searchResultPositionsCounter(state,data){
      state.searchResultPositionsCounter = data;
    }
  },
  actions: {
    login({commit}, user){
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({url: apiURL + '/api/auth/local', data: user, method: 'POST' })
        .then(resp => {
          const token = 'Bearer '+resp.data.jwt
          const user = resp.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('userID', user.id)
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', resp.data)
          resolve(resp)
        })
        .catch(err => {
          commit('auth_error')
          localStorage.removeItem('token')
          localStorage.removeItem('userID')
          reject(err)
        })
      })
  },
  checkRole({commit}){
  return  axios
    .post(apiURL+'/api/scripts-lists/checkRole')
     .then(res=>{
       commit('setRole', res.data.role)
       return res.data.role
     })
  },
  scriptList({commit,state}, ){
    return new Promise((resolve, reject) => {
      
      axios
      .get(this.state.apiURL + '/api/lessons',{
       headers: {
          Authorization: state.token,
        },
      } )
     
      .then(response => {
        commit('setScripts', response.data)

      })
    })
  

  },
  getScriptType({commit,state}, ){
    return new Promise((resolve, reject) => {
      
      axios
      .get(this.state.apiURL + '/api/script-types',{
        headers: {
          Authorization: state.token,
        },

      } )
     
      .then(response => {
        commit('setScriptTypes', response.data.data)

      })
    })
  

  },
  getScript({commit,state},id ){
    return new Promise((resolve, reject) => {
      
      axios
      .get(this.state.apiURL + '/api/scripts-lists/'+id ,{
      // params:{id:id},
       headers: {
          Authorization: state.token,
        },
      } )
     
      .then(response => {
        commit('setScript', response.data.data)

      })
    })
  

  },
 
  addDialog({commit,state}, {scriptId,dialog,dialogId}){
    
    return new Promise((resolve, reject) => {
      
      axios
      .post(this.state.apiURL + '/api/dialogs',{
       headers: {
          Authorization: `Bearer ${state.token}`,
        },
          data:{

          "dialog": dialog,
          "script_id": scriptId,
          "dialog_id":dialogId          
          }
        
      } )
     
      .then(response => {
        
        commit('setDialog', response.data)

      })
    })
  

  },
  addDialogName({commit,state}, {scriptId,dialog,dialogId}){

    return new Promise((resolve, reject) => {
      axios
      .put(this.state.apiURL + '/api/dialogs/'+dialogId,{

          data:{
          "dialog": dialog,
          "script_id": scriptId,
          "x":-130.31948554051965,
          "y":180,

          "dialog_id":dialogId          
          }
        
      } )
      .then(response => {  
       
        
      })
    })
  },
  nodeMove({commit,state}, {scriptId,dialogId,x,y}){

    return new Promise((resolve, reject) => {
      axios
      .put(this.state.apiURL + '/api/dialogs/'+dialogId,{

          data:{
          "script_id": scriptId,
          "x":x,
          "y":y,
          "dialog_id":dialogId          
          }
        
      } )
      .then(response => {  
        let cDialog=state.script.dialogs.find(d=>d.id==dialogId)
        cDialog.attributes.x=x
        cDialog.attributes.y=y
       
      })
    })
  },

  deleteDialog({commit,state}, {scriptId,dialogId}){

    return new Promise((resolve, reject) => {
      axios
      .delete(this.state.apiURL + '/api/dialogs/'+dialogId,{ 
        params:{scriptID:scriptId},
      } )

    })
  },
  deleteScript({commit,state}, {scriptId}){

    return new Promise((resolve, reject) => {
      axios
      .delete(this.state.apiURL + '/api/scripts-lists/'+scriptId,{ 
      } )

    })
  },

  addAnswer({commit,state}, {id,answer,answerId,dialogId,nextDialogId}){
    
    return new Promise((resolve, reject) => {
      if(isNaN(id) || id == null){
      axios
      .post(this.state.apiURL + '/api/answers',{
          data:{
          "answer": answer,
          "answer_id": answerId,
          "dialog_id":dialogId 
          }   
      } )    

    }else{
      axios
      .put(this.state.apiURL + '/api/answers/'+id,{
          data:{
          "answer": answer,
          "dialog_id":dialogId,
          "answer_id": answerId,
          "next_dialog_id": String(nextDialogId)
          }   
      } )  

    }
    })
  

  },

  deleteAnswer({commit,state}, {id,dialogId}){
    
    return new Promise((resolve, reject) => {

      axios
      .delete(this.state.apiURL + '/api/answers/'+id+'?dialogId='+dialogId,{ } )  

    
    })
  

  },

 init({commit,dispatch},query ){

  let scriptId=query.scriptId
  console.log(query);
  setTimeout(() => router.push('/scormpleer/'+scriptId), 700); 
/*
    return new Promise((resolve, reject) => {
     // commit('auth_request')

     axios
     .get(apiURL+'/api/auth/google/callback',{
      params:query
    })

      .then(res => {
        if (res.status !== 200) {
          throw new Error(`Couldn't login to Strapi. Status: ${res.status}`);
        }
        return res;
      })
      .then(resp => {
        console.log(resp);
        const token = 'Bearer '+resp.data.jwt
          const user = resp.data.user
          localStorage.setItem('token', token)
          localStorage.setItem('userID', user.id)
          localStorage.setItem('username', user.username);
          axios.defaults.headers.common['Authorization'] = token
          commit('auth_success', resp.data)
          dispatch('checkRole')
          setTimeout(() => router.push('/'), 500); 

      })
      .catch(err => {
        commit('auth_error', err)
        localStorage.removeItem('token')
        reject(err)
      })
    })*/
  },
  userInit({commit,dispatch},query ){

    let locale=query.locale
    let token=query.jw
    let u=query.u
    let scriptId=query.scriptId

    localStorage.setItem('token', token)
    localStorage.setItem('located', locale)
    localStorage.setItem('userID', u)
    axios.defaults.headers.common['Authorization'] = token
    commit('initSuccess', {token,locale,u})
    setTimeout(() => router.push('/pleer/'+scriptId), 800); 
  
    },
    initLesson({commit,dispatch,state},{query,email,code} ){
      localStorage.removeItem('token')

      localStorage.removeItem('userID')
      delete axios.defaults.headers.common['Authorization']
      let data=query
      data.email=email
      if(code){
        data.code=code
      }

     return axios
      .post(this.state.apiURL + '/api/lessons/initLesson',{
        data:data
      }).then(resp=>{
        if(code){
        const token ='Bearer '+ resp.data.jwt
        axios.defaults.headers.common['Authorization'] = token
        localStorage.setItem('token', token)
        commit('set_token',token )
        router.push('pleer/'+query.s)
       }
       if(resp.data.ok){
        return true
       }
      })

    },
  
 async   initSCORMuser({commit,dispatch,state},{query} ){

      let data=query
      return await axios
      .post(this.state.apiURL + '/api/lessons/initSCORMuser',{
        data:data
      }).then(resp=>{
        const token =resp.data.JWT.jwt? 'Bearer '+ resp.data.JWT.jwt:'Bearer '+ resp.data.JWT
        axios.defaults.headers.common['Authorization'] = token
        localStorage.setItem('token', token)
        commit('set_token',token )

        setTimeout(()=>{
          router.push('scormpleer/'+query.scriptID)
        },500)
        
      }
      )

    },
    getScormUser({commit,dispatch,state},{query} ){
      var instance = axios.create();
delete instance.defaults.headers.common['Authorization'];

      let data=query
      return instance
      .post(this.state.apiURL + '/api/lessons/getSCORMuser ',{
        data:data
      }).then(resp=>{
         // console.log(resp);
        
      }
      )

    },


    

  logout({commit}){

      commit('logout')
      localStorage.removeItem('token')

      localStorage.removeItem('userID')
      delete axios.defaults.headers.common['Authorization']
      
    
  }
  ,
  getSearchIDs({commit,state}, {searchStr}) {
    return new Promise((resolve, reject) => {
      axios
      .post(this.state.apiURL + '/api/scripts-lists/search',{
         headers: {
          Authorization: `Bearer ${state.token}`,
         },
         data:{
          "scriptID": state.script.id,
          "searchStr": searchStr,
        }   
      } )
      .then(response => {
        
        commit('setSearchIDs', response.data.data)

      })    

    });
  },
}
})