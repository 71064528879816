import { createApp } from 'vue'
import App from './App.vue'
import {langMethods}  from './mixins/langConxtant.js'
//import {recorder}  from './plugins/lib/js/libvorbis.min.js'
import Axios from 'axios'
import vuetify from './plugins/vuetify'
import Vuex from 'vuex';
import { loadFonts } from './plugins/webfontloader'
import { AVPlugin }  from 'vue-audio-visual'
import router from './router'
import store from './store'
const app = createApp(App)
//import OpusMediaRecorder from 'opus-media-recorder';
//console.log(OpusMediaRecorder);
//window.MediaRecorder = OpusMediaRecorder;
app.config.globalProperties.$http = Axios;
const token = localStorage.getItem('token')
if (token) {
  app.config.globalProperties.$http.defaults.headers.common['Authorization'] = token
}

//import VueSocketIO from 'vue-3-socket.io'


/*import io from 'socket.io-client';
const socketio = new VueSocketIO({
    debug: true,
    connection: io('ws://91.221.67.104:1337',{ transports : ['websocket'] }),
	// http: ourselves: port
    extraHeaders: {"Access-Control-Allow-Origin": '*'},
});*/



loadFonts()
Axios.interceptors.response.use(
  response =>{

  return response
},
error => {
 
  if (error.response.status === 401) {
    //window.location.href = '/login';
  }
  if (error.response.status === 400) {
    store.commit('setSnackbar',{data:error.response.data.error.details.Error, color:"red"})
  }
  if (error.response.status === 403) {
    store.commit('setSnackbar',{data:error.response.data.error.details.Error, color:"red"})
  }
  if (error.response.status === 500) {
    store.commit('setSnackbar',{data:langMethods.methods.getLangConst('500'), color:"red"})
    
  }
  if(error.toJSON().message === 'Network Error'){
    store.commit('setSnackbar',{data:'Service unavailable, please try again later', })
    
  }
 return error
});

app.mixin(langMethods)
//app.component('AudioVisual',AudioVisual)
//app.mixin(recorder)
app.use(vuetify)
app.use(AVPlugin)
app.use(router)
app.use(store)
app.use(Vuex)
//  .use(socketio)
app.mount('#app')
