/* eslint-disable */
import { createWebHistory, createRouter } from "vue-router";
import store from '@/store/index.js'


const routes = [
 /* {
    path: "/login",
    name: "LoginPage",
    component: () => import("@/views/LoginPage.vue"),
  /*  meta: {
      requiresAuth: true,
    },
  },*/
 
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/MyScripts.vue"),
   meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/pleer/:id",
    name: "pleer",
    component: () => import("@/components/hPleer.vue"),
   meta: {
      requiresAuth: true,
    },
  }, 


  {
    path: "/scormpleer/:id",
    name: "scormpleer",
    component: () => import("@/components/hPleer.vue"),
   meta: {
      requiresAuth: true,
    },
  }, 
  {
    path: "/initlink",
    name: "init",
    component: () => import("@/views/initStudent.vue"),
   meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/userInit",
    name: "userInit",
    component: () => import("@/views/initUser.vue"),
   meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/initSCORMuser",
    name: "initSCORMuser",
    component: () => import("@/views/initSCORMuser.vue"),
   meta: {
      requiresAuth: false,
    },
  },
  {
    path: "/getSCORMuser",
    name: "getScormUser",
    component: () => import("@/views/getScormUser.vue"),
   meta: {
      requiresAuth: false,
    },
  },
  /*{
    path: "/:catchAll(.*)",
    beforeEnter: (to, from, next) => {
      let link =
        process.env.NODE_ENV === "production" ?
        to.fullPath :
        process.env.VUE_APP_WT_HOST + to.fullPath;
      let target = "_blank";

   getSCORMuser   

      next(false);
      //    abc.replace(/([^:]\/)\/+/g, "$1");
    },
  }*/


]

const router = createRouter({
  history: createWebHistory(),
  routes,
});
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next()
      return
    }
    next('/login') 
  } else {
    next() 
  }
})
export default router;