<template>
  <div id="app">
      <v-app :key="key">
        <v-app-bar
        v-if="!$vuetify.display.mobile&&$route.name!='scormpleer'&&$route.name!='init'&&$route.name!='initSCORMuser'&&$route.name!='getScormUser'" 
       theme="dark"
      app
      height="48"
      color="#242D3F"
    >

       
          <div class="d-flex pa-2">
         <a class="ma-2" href="/"> <v-img    width="30px" :src='require("@/assets/m.png")'></v-img></a>
           <v-tabs 
          v-if="isLoggedIn" 
          v-model="tab"
          >
            <v-tab class="ml-2" to="/"  ><v-icon>mdi-view-list</v-icon> <div class="ml-2"> {{getLangConst('myAssignments')}} </div> </v-tab>
           <!-- <v-tab to="/about" >{{getLangConst('about')}}</v-tab>-->
          </v-tabs>
          </div>
          <template v-if="isLoggedIn&&$route.name=='pleer'" >
          <span class="ml-5 text-subtitle-2">{{scriptName}}</span>
         <!-- <v-chip size="small" class="text-white ml-5" color="rgba(80, 213, 255, 0.29)" label>{{typeName}}</v-chip>-->
          </template> 
        <v-spacer></v-spacer>
        <template v-if="isLoggedIn&&$route.name=='dialog'" >





            <v-divider class="mx-2 " vertical></v-divider>
          </template>
        <!--<v-btn @click="setLocated" style="text-transform:uppercase;">{{located}}</v-btn>-->

        <v-menu  v-if='isLoggedIn'  >
              <template v-slot:activator="{  props }">
              <!--  <v-btn color="#4863FF"  size="small" variant="icon" v-bind="props" icon="mdi-account-outline" >
                
                </v-btn>-->
                <v-avatar style="color:#fff;" v-bind="props"  icon="mdi-account-outline" size="30" class="ma-3 mr-5 " color="#4863FF">
        
      </v-avatar>
              </template>
              <v-list theme="light" > 
                <!--<v-list-item width="200px" @click="$router.push({ path: `/account/` })">
                 <v-icon class=" mr-2">mdi-account-outline</v-icon> {{getLangConst('account')}}  
                </v-list-item>-->
              <v-list-item class="mx-3 rounded-lg" width="250px" @click="logout">
                  <v-icon size="20px" class=" mr-3 mb-1">mdi-exit-to-app</v-icon>
                   <span  class="s-15"> {{getLangConst('exit')}}  </span>
                </v-list-item>
                
              </v-list>
            </v-menu>


    </v-app-bar>
    <v-main :class="!$vuetify.display.mobile?'mt-5':''" >  
 <router-view  ></router-view>
    
    
      <v-snackbar
      app
      :class="$vuetify.display.mobile?'mt-n15':'mt-n5'"
        v-model="snackbar.show"
        timeout="3000"
        :color="snackbar.color"
      >
        {{ snackbar.text }}
  

      </v-snackbar>
     </v-main>    
    </v-app>
  </div>
</template>
<script> 

export default { 
  name: "App",

   data() {
     return { 
      key:1,
      t:true,
      
      };
     },
      computed : {
            role(){return this.$store.getters.getRole},
      isLoggedIn (){  return  this.$store.getters.isLoggedIn},
      located (){  return  this.$store.getters.getLocated},
      scriptName(){  return  this.$store.getters.getScriptName},
      snackbar(){ return this.$store.getters.snackbar},
      tab(){
        return this.$route.name=='pleer'?1:0
      }

      
    },
     
    methods: {
      setLocated(){
        let loc=this.located=='en'?'ru':'en'
        this.$store.commit('setLocated',loc)
         this.key++
      },


      logout: function () {
        this.$store.dispatch('logout')
        .then(() => {
          if(this.role=='student'){
           window.location.href= 'https://sonotiv.com'
          }else{
            window.location.href='https://editor.sonotiv.com/login'
          }
          
        })
      },


    },
      mounted() {
  },
   watch: {

    },
   };
 </script> 
 <style >
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}
.v-label{
  white-space: normal!important;
  overflow-wrap:break-word!important;
  word-wrap: break-word!important;
}
.v-selection-control .v-label{
  height: none!important;
}
nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
#app {

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}
.v-tab--selected{
  color: #00d1ff!important;
}
.v-main{
  background: #f7f7f7;
}

.v-btn {
  font-weight:100;
border-radius: 100px;
 text-transform: none!important;;
font-style: normal;
font-weight: 400;
letter-spacing: 0.0302857143em;
}
.v-btn--size-x-large{
  font-size: 1rem;
}
a {
  text-decoration: none;
}
select, input {
  background-color:'#4863ff1b';
  border: none;
}
.v-field--active  {
background-color:#4863ff1b;
border: none;

}


.v-field--focused div div:focus {
   outline:none;
}
.v-chip {
    border-radius: 8px;
}
.text-large{
  font-family: Roboto;
font-size: 25px;
font-weight: 500;
line-height: 40px;
}
.text-editor-card{

              position:fixed!important;
              height: calc(100vh - 48px) !important;
              top: 48px;
              bottom: 0px;
              right:0;
              width:30%;
}
.text-editor-card-onsearch{
                position:fixed!important;
              height: calc(100vh - 48px) !important;
              top: 48px;
              bottom: 0px;
              right:256px;
              width:calc(30% - (150px/2));
}

.text-small{

font-family: Roboto;
font-size: 24px;
font-weight: 400;
line-height: 32px;
letter-spacing: 0em;
text-align: center;

}
/*.v-field__input{
  border:none;
  max-height:40px;
}*/
.v-field__append-inner{
align-items: flex-start!important;
}
nav {
  padding: 30px;
}
  a {
    font-weight: bold;
    color: #4863FF;}

    .router-link-exact-active {
      color: #42b983;
    }
  

html{
  overflow-y: scroll!important;
  font-weight:300!important;
  font-family: Inter, sans-serif !important;
}
#app{
   font-family: Inter, sans-serif !important;
}


.v-file-input .v-label.v-field-label{
  margin-top: -9px!important;
  
}
.v-label v-field-label{
font-size: 13px;
  font-family: Inter, sans-serif !important
}
.v-field__field{
  font-size: 14px!important;  
  /*font-weight: 400!important;*/
}
.v-expansion-panel-text__wrapper {
    padding-left: 0px!important;
    padding-right: 0px!important;
  }
 div::-webkit-scrollbar {
  width: 5px;
}

div::-webkit-scrollbar-track {
  /*box-shadow: 5px 5px 5px -5px rgba(34, 60, 80, 0.2) inset;
  background-color: #f9f9fd;*/
  border-radius: 10px;
  margin-bottom: 8px;
  margin-top: 8px;
}

div::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #a3a3a3;
}

.lName {
  margin: 0; 
    -webkit-line-clamp: 2; 
    display: -webkit-box; 
    -webkit-box-orient: vertical; 
    overflow: hidden;
}
.v-card-text{
  font-size: 0.812rem;
}
.v-card-title{
  font-size: 18px;
}
.v-card-title{
  font-size: 18px;
}
.v-list-item-title{
font-size: 15px;
}
.v-list-item{
  padding: 4px 8px;
}
 
div{
   font-size: 0.814rem;
}
p{
   font-size: 0.814rem;
}
.s-13{
  font-size: 13px;
  font-family: Inter, sans-serif !important;
}
.s-15{
  font-size: 15px;
  font-family: Inter, sans-serif !important;
}
.v-list-item--variant-text .v-list-item__overlay {
    background: #000000;
    
}
.v-tooltip .v-overlay__content {
  background: #000000ce!important;
}
.v-select{
   --v-input-control-height: 8px!important;
  
    --v-input-padding-bottom: 5px!important;
}
.v-input--density-default .v-field--variant-outlined {
 --v-field-padding-bottom: 3px!important;
}
.v-field__prepend-inner{
      display: flex;
      align-items: center!important;
         padding-top: 0px!important;
}
.v-field__append-inner {
   display: flex;
   align-items: center !important;
   margin-top: 0px!important;

}
.v-file-input .v-label.v-field-label{
  margin-top: -5px!important;

}
.text-h5{
  font-family: Inter, sans-serif !important;
}
</style>
