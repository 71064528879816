// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
  display: {
    mobileBreakpoint: 'md',
  },
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: '#4863FF', // #E53935
          'primary-darken-1':'#21446E',
           // #FFCDD2
          
        }
      },
    },
  },

})