



import store from '@/store'

const langCatalog=[
    {
        id:"id",
        
        en:"en",
        
        },
        {
        id:"400",
        
        en:"An internal error occurred while saving data. Please, try later",
        
        },
        {
        id:"403",
        
        en:"You don't have permission to perform this action",
        
        },
        {
        id:"500",
        
        en:"An error occurred in obtaining the data. Please, try later.",
        
        },
        {
        id:"about",
        
        en:"About",
        
        },
        {
        id:"account",
        
        en:"Profile",
        
        },
        {
        id:"activate",
        
        en:"Assign",
        
        },
        {
        id:"active",
        
        en:"Active",
        
        },
        {
        id:"activeAll",
        
        en:"Active",
        
        },
        {
        id:"activeMic",
        
        en:"Active microphone",
        
        },
        {
        id:"actualAddress",
        
        en:"Address",
        
        },
        {
        id:"add",
        
        en:"Add",
        
        },
        {
        id:"addAnswer",
        
        en:"Add answer",
        
        },
        {
        id:"addFirstDialog",
        
        en:"Nothing found",
        
        },
        {
        id:"addWord",
        
        en:"Enter keywords",
        
        },
        {
        id:"admin",
        
        en:"Admin",
        
        },
        {
        id:"agreement_1",
        
        en:"By clicking «Create account», I agree to",
        
        },
        {
        id:"agreement_2",
        
        en:" the Terms of Service and Privacy Policy",
        
        },
        {
        id:"alertNode",
        
        en:"No link with the following phrase",
        
        },
        {
        id:"alignment",
        
        en:"Auto-align",
        
        },
        {
        id:"all",
        
        en:"All",
        
        },
        {
        id:"allScript",
        
        en:"All scripts",
        
        },
        {
        id:"allStatus",
        
        en:"All statuses",
        
        },
        {
        id:"alreadyRegistered",
        
        en:"Already have an account?",
        
        },
        {
        id:"angry",
        
        en:"irritated",
        
        },
        {
        id:"annotation",
        
        en:"Coach feedback",
        
        },
        {
        id:"annually",
        
        en:"Annually",
        
        },
        {
        id:"answerVariants",
        
        en:"Answer choice",
        
        },
        {
        id:"apply",
        
        en:"Apply",
        
        },
        {
        id:"appointmentPeriod",
        
        en:"Appointment period from",
        
        },
        {
        id:"Archive",
        
        en:"Archive",
        
        },
        {
        id:"Arhive",
        
        en:"Archive",
        
        },
        {
        id:"Arhives",
        
        en:"Archive",
        
        },
        {
        id:"assigned",
        
        en:"Assigned",
        
        },
        {
        id:"assignedScripts",
        
        en:"Assigned Scripts",
        
        },
        {
        id:"autoSend",
        
        en:"Recognize voice automatically after recording",
        
        },
        {
        id:"back",
        
        en:"Back",
        
        },
        {
        id:"backMain",
        
        en:"Go back to the main page",
        
        },
        {
        id:"bad2Pass",
        
        en:"Passwords do not match. Try again",
        
        },
        {
        id:"badPass",
        
        en:"The password does not meet the requirements.",
        
        },
        {
        id:"bankCard",
        
        en:"Credit card",
        
        },
        {
        id:"bind",
        
        en:"link",
        
        },
        {
        id:"block",
        
        en:"Block",
        
        },
        {
        id:"blocked",
        
        en:"Blocked",
        
        },
        {
        id:"blockedAll",
        
        en:"Blocked",
        
        },
        {
        id:"businessDesc",
        
        en:"Best for mid-sized teams",
        
        },
        {
        id:"Bussines",
        
        en:"Business",
        
        },
        {
        id:"byOrganization",
        
        en:"By organization",
        
        },
        {
        id:"byScript",
        
        en:"By script",
        
        },
        {
        id:"byTariff",
        
        en:"By plan",
        
        },
        {
        id:"cancel",
        
        en:"Cancel",
        
        },
        {
        id:"canceled",
        
        en:"Canceled",
        
        },
        {
        id:"cancelOrder",
        
        en:"Cancel order",
        
        },
        {
        id:"cancelSubscription",
        
        en:"Cancel subscription",
        
        },
        {
        id:"cancelSubscriptionText_1",
        
        en:"You can cancel your subscription at any time. Cancellations become effective as of your next renewal date, and Your services will continue until the end of",
        
        },
        {
        id:"cancelSubscriptionText_2",
        
        en:"If you cancel your subscription, autopayments will stop working in your profile.",
        
        },
        {
        id:"caseSensitive",
        
        en:"Match case",
        
        },
        {
        id:"changeActor",
        
        en:"Change character",
        
        },
        {
        id:"changePicture",
        
        en:"Change picture",
        
        },
        {
        id:"changeTo",
        
        en:"Replace with",
        
        },
        {
        id:"character",
        
        en:"Character",
        
        },
        {
        id:"chart",
        
        en:"Flowchart",
        
        },
        {
        id:"choosePlan",
        
        en:"Choose this plan",
        
        },
        {
        id:"cientReplic",
        
        en:"Speech",
        
        },
        {
        id:"client",
        
        en:"Customer",
        
        },
        {
        id:"client-speak",
        
        en:"Customer service",
        
        },
        {
        id:"client-speakDesc",
        
        en:"An employee will learn the script almost literally. The conversation is a template, with branching.Suitable for call center or customer service.",
        
        },
        {
        id:"close",
        
        en:"Close",
        
        },
        {
        id:"coincidesAddress",
        
        en:"Matches legal address",
        
        },
        {
        id:"companyName",
        
        en:"Company name",
        
        },
        {
        id:"companyName",
        
        en:"Company name",
        
        },
        {
        id:"companyProfile",
        
        en:"Company profile",
        
        },
        {
        id:"complete",
        
        en:"Complete",
        
        },
        {
        id:"completed",
        
        en:"Completed",
        
        },
        {
        id:"completedSuccessfully",
        
        en:"Completed successfully",
        
        },
        {
        id:"completion",
        
        en:"Completion",
        
        },
        {
        id:"confirmEmail",
        
        en:"Confirm your email",
        
        },
        {
        id:"contactUs",
        
        en:"Contact us",
        
        },
        {
        id:"contactUsPrice",
        
        en:"Contact sale",
        
        },
        {
        id:"couldntPay",
        
        en:"Couldn't pay",
        
        },
        {
        id:"counteEmployees",
        
        en:"How many employees work at your company?",
        
        },
        {
        id:"create",
        
        en:" Create",
        
        },
        {
        id:"create",
        
        en:"Create",
        
        },
        {
        id:"createAccount",
        
        en:"Create account",
        
        },
        {
        id:"createDate",
        
        en:"Creation date",
        
        },
        {
        id:"createScriptDesk_1",
        
        en:"Create your first script",
        
        },
        {
        id:"createScriptDesk_2",
        
        en:"Your content will be saved on this page",
        
        },
        {
        id:"creatingAccount",
        
        en:"Create account",
        
        },
        {
        id:"CRMint",
        
        en:"Integration with CRM  <br/>  - on request",
        
        },
        {
        id:"currency",
        
        en:"usd",
        
        },
        {
        id:"cusromerService",
        
        en:"Technical support: tickets +",
        
        },
        {
        id:"customerInformation",
        
        en:"Customer information",
        
        },
        {
        id:"delete",
        
        en:"Delete",
        
        },
        {
        id:"deleteAccount",
        
        en:"You can delete a profile by contacting Customer Service: support@sonotiv.com",
        
        },
        {
        id:"deleted",
        
        en:"Deleted",
        
        },
        {
        id:"deleteLink",
        
        en:"Delete link",
        
        },
        {
        id:"Demo",
        
        en:"Trial",
        
        },
        {
        id:"desc",
        
        en:"Description",
        
        },
        {
        id:"dictionaries",
        
        en:"Dictionaries",
        
        },
        {
        id:"Dirty",
        
        en:"Draft",
        
        },
        {
        id:"download",
        
        en:"Download",
        
        },
        {
        id:"downloadAndFill",
        
        en:"and fill out the template for importing students",
        
        },
        {
        id:"edit",
        
        en:"Edit",
        
        },
        {
        id:"editor",
        
        en:"Editor",
        
        },
        {
        id:"editorHint",
        
        en:"Selection of student",
        
        },
        {
        id:"editorTeacher",
        
        en:"Editor and Teacher",
        
        },
        {
        id:"editWarn",
        
        en:"When switching to the 'Editing' mode, the script will be unpublished",
        
        },
        {
        id:"email",
        
        en:"Email",
        
        },
        {
        id:"emailMsg",
        
        en:"Please enter a valid e-mail address",
        
        },
        {
        id:"EnableForUse",
        
        en:"Enable",
        
        },
        {
        id:"end",
        
        en:"The end",
        
        },
        {
        id:"endButtonText",
        
        en:"Result Information (for the report)",
        
        },
        {
        id:"enrolment",
        
        en:"Enrollment",
        
        },
        {
        id:"episode",
        
        en:"Episode",
        
        },
        {
        id:"errLogin",
        
        en:"Incorrect username or password",
        
        },
        {
        id:"exercise",
        
        en:"Exercise",
        
        },
        {
        id:"exit",
        
        en:"Log out",
        
        },
        {
        id:"extendedSupport",
        
        en:"Extended support - on request:  <br/> - Methodology  <br/> - Voice acting of scripts by actors  <br/> - Additional characters",
        
        },
        {
        id:"filter",
        
        en:"Filter",
        
        },
        {
        id:"finish",
        
        en:"Completion",
        
        },
        {
        id:"fio",
        
        en:"Full Name",
        
        },
        {
        id:"forAmount",
        
        en:"for an amount",
        
        },
        {
        id:"forbiddenWords",
        
        en:"Banned words",
        
        },
        {
        id:"free",
        
        en:"free",
        
        },
        {
        id:"generate",
        
        en:"Generate speech",
        
        },
        {
        id:"global",
        
        en:"Global",
        
        },
        {
        id:"go",
        
        en:"Go",
        
        },
        {
        id:"goAgain",
        
        en:"Try again",
        
        },
        {
        id:"goTo",
        
        en:"Follow",
        
        },
        {
        id:"h",
        
        en:"h",
        
        },
        {
        id:"halfYear",
        
        en:"six months",
        
        },
        {
        id:"happy",
        
        en:"happy",
        
        },
        {
        id:"hard",
        
        en:"Without suggestions",
        
        },
        {
        id:"hintDesc",
        
        en:"The selection of the hint mode is carried out by the teacher when assigning training",
        
        },
        {
        id:"hints",
        
        en:"Show answers",
        
        },
        {
        id:"hints_",
        
        en:"Hints",
        
        },
        {
        id:"howItWorks",
        
        en:"How it works",
        
        },
        {
        id:"id",
        
        en:"en",
        
        },
        {
        id:"import",
        
        en:"Import",
        
        },
        {
        id:"imports",
        
        en:"Import",
        
        },
        {
        id:"improve",
        
        en:"Upgrade",
        
        },
        {
        id:"incorrectLogin",
        
        en:"Incorrect login or password",
        
        },
        {
        id:"increase",
        
        en:"Zoom in",
        
        },
        {
        id:"IndividualEntrepreneur",
        
        en:"Sole proprietor",
        
        },
        {
        id:"inn",
        
        en:"SSN / ITIN / EIN",
        
        },
        {
        id:"inProgress",
        
        en:"in Progress",
        
        },
        {
        id:"invite",
        
        en:"Add a user",
        
        },
        {
        id:"invoice",
        
        en:"invoice",
        
        },
        {
        id:"invoices",
        
        en:"Invoices issued",
        
        },
        {
        id:"ispringDownload",
        
        en:"Upload from iSpring",
        
        },
        {
        id:"keyword",
        
        en:"Keyword",
        
        },
        {
        id:"keywordAdded",
        
        en:"Keyword added",
        
        },
        {
        id:"keywordBeenAdded",
        
        en:"Keyword was already added earlier",
        
        },
        {
        id:"keyWords",
        
        en:"Show keywords",
        
        },
        {
        id:"keywords",
        
        en:"Keywords",
        
        },
        {
        id:"kpp",
        
        en:"IEC",
        
        },
        {
        id:"lastAttempt",
        
        en:"Last attempt",
        
        },
        {
        id:"lastAttemptResult",
        
        en:"Final attempt",
        
        },
        {
        id:"lastAttemptTo",
        
        en:"The period of the last attempt by",
        
        },
        {
        id:"legalAddress",
        
        en:"Legal address",
        
        },
        {
        id:"legalEntity",
        
        en:"Legal entity",
        
        },
        {
        id:"licensesDistributed",
        
        en:"Licenses are distributed",
        
        },
        {
        id:"licensesPurchased",
        
        en:"Licenses purchased",
        
        },
        {
        id:"linkSent",
        
        en:"Link was sent to email",
        
        },
        {
        id:"list",
        
        en:"Text view",
        
        },
        {
        id:"loadVoice",
        
        //en:"Upload your character's voiceover file or use speech recognize mode",
        en:"Upload your character's voiceover file",
        },
        {
        id:"login",
        
        en:"Log In",
        
        },
        {
        id:"mailConfirm",
        
        en:"Check your email. We've sent you a link to al.mailiancts@gmail.com. Please follow the link to confirm your email address.",
        
        },
        {
        id:"managerReplic",
        
        en:"Learner's phrase",
        
        },
        {
        id:"manager-speak",
        
        en:"Sales",
        
        },
        {
        id:"manager-speakDesc",
        
        en:"An employee will learn how to make a decision, to be flexible,  to achieve a sale.<br/>Suitable for dialogue with the customer, cold calling, handling objections.",
        
        },
        {
        id:"message",
        
        en:"Message",
        
        },
        {
        id:"minUser",
        
        en:"min. per user (speech recognition)",
        
        },
        {
        id:"minUserNoTime",
        
        en:"Time for recognition - on request",
        
        },
        {
        id:"minutesRecognition",
        
        en:"Number of minutes for speech recognition",
        
        },
        {
        id:"missingKw",
        
        en:"Keywords are missing",
        
        },
        {
        id:"month",
        
        en:"Month",
        
        },
        {
        id:"monthly",
        
        en:"Monthly",
        
        },
        {
        id:"monthlyYearly",
        
        en:"Monthly and annually",
        
        },
        {
        id:"myAssignments",
        
        en:"My assignments",
        
        },
        {
        id:"myScripts",
        
        en:"My scripts",
        
        },
        {
        id:"mYSubs",
        
        en:"Monthly or annually subscription",
        
        },
        {
        id:"myTasks",
        
        en:"My assignments",
        
        },
        {
        id:"NewPass",
        
        en:"New password",
        
        },
        {
        id:"NewPassMessage",
        
        en:"Password must consist of at least 8 characters, contain big and small letters, numbers, and additional characters",
        
        },
        {
        id:"newPassword",
        
        en:"Enter and confirm your password",
        
        },
        {
        id:"newScript",
        
        en:"New script",
        
        },
        {
        id:"newSlide",
        
        en:"New slide",
        
        },
        {
        id:"noAccount",
        
        en:"No account?",
        
        },
        {
        id:"noCorrect",
        
        en:"Check the correctness of the entered data",
        
        },
        {
        id:"noCourses",
        
        en:"There are no suitable courses",
        
        },
        {
        id:"noLesson",
        
        en:"You don't have any assigned courses",
        
        },
        {
        id:"noLimitScripts",
        
        en:"Unlimited number of scripts",
        
        },
        {
        id:"noRestrictions",
        
        en:"Unlimited",
        
        },
        {
        id:"normal",
        
        en:"friendly",
        
        },
        {
        id:"noStudents",
        
        en:"No students found",
        
        },
        {
        id:"notActive",
        
        en:"Not active",
        
        },
        {
        id:"notDelete",
        
        en:"The initial slide cannot be deleted",
        
        },
        {
        id:"notDeleteMessage",
        
        en:"You can't delete the start and end slide",
        
        },
        {
        id:"notFound",
        
        en:"Not found",
        
        },
        {
        id:"notLoop",
        
        en:"You can't loop the dialog",
        
        },
        {
        id:"notMobile",
        
        en:"The functionality of the editor is not intended for viewing on mobile devices. Use a computer.",
        
        },
        {
        id:"num",
        
        en:"number",
        
        },
        {
        id:"numberAssigned",
        
        en:"Number of assigned",
        
        },
        {
        id:"numberAttempts",
        
        en:"Number of attempts",
        
        },
        {
        id:"numberCompleted",
        
        en:"Number of completed",
        
        },
        {
        id:"numberScript",
        
        en:"Number of scripts",
        
        },
        {
        id:"numberTrainees",
        
        en:"Number of trainees",
        
        },
        {
        id:"numberUsers",
        
        en:"Number of users",
        
        },
        {
        id:"offer",
        
        en:"By clicking on 'Purchase', you consent to terms of Use",
        
        },
        {
        id:"offerLink",
        
        en:"Public offer",
        
        },
        {
        id:"oneRecord",
        
        en:"Record",
        
        },
        {
        id:"opportunities",
        
        en:"Features",
        
        },
        {
        id:"or",
        
        en:"or",
        
        },
        {
        id:"orderCancelled",
        
        en:"Order cancelled",
        
        },
        {
        id:"orderPayment",
        
        en:"Order payment",
        
        },
        {
        id:"organizationProfile",
        
        en:"Company information",
        
        },
        {
        id:"pass",
        
        en:"Start",
        
        },
        {
        id:"passMess",
        
        en:"Password must consist of at least 8 characters, contain big and small letters, numbers, and additional characters",
        
        },
        {
        id:"password",
        
        en:"Password",
        
        },
        {
        id:"passwordRecovery",
        
        en:" Password recovery",
        
        },
        {
        id:"pause",
        
        en:"Pause",
        
        },
        {
        id:"payingYear",
        
        en:"when paying for a year",
        
        },
        {
        id:"paymentCycle",
        
        en:"Choose your payment cycle",
        
        },
        {
        id:"paymentDate",
        
        en:"Payment date",
        
        },
        {
        id:"paymentMethod",
        
        en:"Payments detail",
        
        },
        {
        id:"paymentPeriod",
        
        en:"Payment period",
        
        },
        {
        id:"paymentTariff",
        
        en:"Application for payment of the tariff",
        
        },
        {
        id:"paymentTariff",
        
        en:"payment for the tariff",
        
        },
        {
        id:"perEmployee",
        
        en:"per employee when paying for the year",
        
        },
        {
        id:"personalData",
        
        en:"Personal data",
        
        },
        {
        id:"personalProfile",
        
        en:"Profile",
        
        },
        {
        id:"perUser",
        
        en:"Per user per month",
        
        },
        {
        id:"phone",
        
        en:"Phone",
        
        },
        {
        id:"phraseMess",
        
        en:"The phrase must contain no more than 30 words.",
        
        },
        {
        id:"plan",
        
        en:"Plan",
        
        },
        {
        id:"play",
        
        en:"Play",
        
        },
        {
        id:"post",
        
        en:"Position",
        
        },
        {
        id:"Premium",
        
        en:"Premium",
        
        },
        {
        id:"premiumDesc",
        
        en:"Best for large company",
        
        },
        {
        id:"price",
        
        en:"Price",
        
        },
        {
        id:"priceRequest",
        
        en:"Price on request",
        
        },
        {
        id:"profileSettings",
        
        en:"Settings",
        
        },
        {
        id:"promo",
        
        en:"Promo code",
        
        },
        {
        id:"Publish",
        
        en:"Published",
        
        },
        {
        id:"publishWarning",
        
        en:"To view it, you need to publish",
        
        },
        {
        id:"puzzled",
        
        en:"interested",
        
        },
        {
        id:"quart",
        
        en:"3 months",
        
        },
        {
        id:"recognition",
        
        en:"Recognition is being performed",
        
        },
        {
        id:"recognitionError",
        
        en:"Recognition error occurred, please try again",
        
        },
        {
        id:"record",
        
        en:"Record",
        
        },
        {
        id:"reduce",
        
        en:"Zoom out",
        
        },
        {
        id:"registerAsCompany",
        
        en:"Register as a company",
        
        },
        {
        id:"registerAsCompany",
        
        en:"Register as a company",
        
        },
        {
        id:"remindCancel",
        
        en:"User with provided email not found. User with provided email not found",
        
        },
        {
        id:"remindConfirm",
        
        en:"You will get an email with instructions on resetting your password. If it doesn't arrive, be sure to check your spam folder.",
        
        },
        {
        id:"remindMessage",
        
        en:"Enter your email to reset password",
        
        },
        {
        id:"remindPassword",
        
        en:"remind password",
        
        },
        {
        id:"removePublication",
        
        en:"Remove publication",
        
        },
        {
        id:"rename",
        
        en:"Rename",
        
        },
        {
        id:"rename",
        
        en:"Change Attributes",
        
        },
        {
        id:"RepeatPass",
        
        en:" Repeat password",
        
        },
        {
        id:"replace",
        
        en:"Replace",
        
        },
        {
        id:"replaceAll",
        
        en:"Replace all",
        
        },
        {
        id:"replicas",
        
        en:"Slides",
        
        },
        {
        id:"replicaSettings",
        
        en:"Tools",
        
        },
        {
        id:"reports",
        
        en:"Reports",
        
        },
        {
        id:"requiredField",
        
        en:"This is a required field",
        
        },
        {
        id:"resultText",
        
        en:"Result text (displayed in reports)",
        
        },
        {
        id:"role",
        
        en:"Role",
        
        },
        {
        id:"run",
        
        en:"Run",
        
        },
        {
        id:"save",
        
        en:"Save",
        
        },
        {
        id:"Scormint",
        
        en:"Integration with LMS (Scorm)",
        
        },
        {
        id:"scriptEditor",
        
        en:"Script Editor",
        
        },
        {
        id:"scriptName",
        
        en:"Script name",
        
        },
        {
        id:"scriptPurpose",
        
        en:"Date of Enrollment",
        
        },
        {
        id:"scripts",
        
        en:"Scripts",
        
        },
        {
        id:"scripts",
        
        en:"scripts",
        
        },
        {
        id:"scriptsLimit",
        
        en:"Scripts limit",
        
        },
        {
        id:"scriptsReports",
        
        en:"Scripts report",
        
        },
        {
        id:"scriptCard",
        
        en:"Script Data",
        
        },
        {
        id:"search",
        
        en:"Search",
        
        },
        {
        id:"searchByFio",
        
        en:"Search by Name",
        
        },
        {
        id:"searchByName",
        
        en:"Search by title",
        
        },
        {
        id:"secretCode",
        
        en:"Verification code",
        
        },
        {
        id:"secretCodeDesc",
        
        en:"A verification code has been sent to your email.",
        
        },
        {
        id:"secretCodeTitle",
        
        en:"Enter Verification code",
        
        },
        {
        id:"sector",
        
        en:"Industry",
        
        },
        {
        id:"select",
        
        en:"Select",
        
        },
        {
        id:"selectFile",
        
        en:"Upload file",
        
        },
        {
        id:"selectFile",
        
        en:"Select a file",
        
        },
        {
        id:"selectHintMode",
        
        en:"Select the hint mode",
        
        },
        {
        id:"selectNext",
        
        en:"Link to speech",
        
        },
        {
        id:"send",
        
        en:"Send",
        
        },
        {
        id:"sendLessonLink",
        
        en:"Send a link",
        
        },
        {
        id:"settings",
        
        en:"Settings",
        
        },
        {
        id:"showAll",
        
        en:"Zoom to fit",
        
        },
        {
        id:"showHints",
        
        en:"Show hints",
        
        },
        {
        id:"showSelected",
        
        en:"Zoom to selection",
        
        },
        {
        id:"single",
        
        en:"Simple dialog",
        
        },
        {
        id:"singleDesc",
        
        en:"Comming soon",
        
        },
        {
        id:"slide",
        
        en:"slide",
        
        },
        {
        id:"speech",
        
        en:"Voice speech",
        
        },
        {
        id:"start",
        
        en:"Start",
        
        },
        {
        id:"Start",
        
        en:"Start",
        
        },
        {
        id:"startDesc",
        
        en:"Best for small teams",
        
        },
        {
        id:"starting",
        
        en:"Starting",
        
        },
        {
        id:"state",
        
        en:"Status",
        
        },
        {
        id:"status",
        
        en:"Status",
        
        },
        {
        id:"statusChanged",
        
        en:"Status changed",
        
        },
        {
        id:"stop",
        
        en:"Stop",
        
        },
        {
        id:"stopWords",
        
        en:"Banned words",
        
        },
        {
        id:"strMon",
        
        en:"1 month",
        
        },
        {
        id:"strMonYear",
        
        en:"12 months",
        
        },
        {
        id:"student",
        
        en:"Student",
        
        },
        {
        id:"studentAdded",
        
        en:"Student added",
        
        },
         {
        id:"addUser",
        
        en:"Invite User",
        
        },
         {
        id:"addUser1",
        
        en:"Add User",
        
        },
        
         {
        id:"studentName",
        
        en:"Student name",
        
        },
        {
        id:"students",
        
        en:"Students",
        
        },
        {
        id:"studentsCount",
        
        en:"From 10 to 100 students",
        
        },
        {
        id:"studentsCount100",
        
        en:"From 100 students",
        
        },
        {
        id:"studentsReports",
        
        en:"Student report",
        
        },
        {
        id:"studentCard",
        
        en:"Result card",
        
        },
        {
        id:"subscriptionDetails",
        
        en:"Subscription Details",
        
        },
        {
        id:"synonyms",
        
        en:"Synonyms",
        
        },
        {
        id:"tariff",
        
        en:"Plan",
        
        },
        {
        id:"tariffPlan",
        
        en:"Plan",
        
        },
        {
        id:"task",
        
        en:"Task",
        
        },
        {
        id:"taskComplete",
        
        en:"The task is completed",
        
        },
        {
        id:"teacher",
        
        en:"Teacher",
        
        },
        {
        id:"teacherAccount",
        
        en:"Training Management",
        
        },
        {
        id:"text",
        
        en:"Text",
        
        },
        {
        id:"find",
        
        en:"Find what",
        
        },
        {
        id:"throw",
        
        en:"reset",
        
        },
        {
        id:"title",
        
        en:"Title",
        
        },
        {
        id:"toBrowser",
        
        en:"Play",
        
        },
        {
        id:"toCancel",
        
        en:"Cancel",
        
        },
        {
        id:"toGogle",
        
        en:" sign up with Google",
        
        },
        {
            id:"toMic",
            
            en:" sign up with Microsoft",
            
            },
        {
        id:"toPay",
        
        en:"To pay",
        
        },
        {
        id:"toPublish",
        
        en:"Publish",
        
        },
        {
        id:"total",
        
        en:"Total",
        
        },
        {
        id:"trainees",
        
        en:" trainees",
        
        },
        {
        id:"unblock",
        
        en:"Unblock",
        
        },
        {
        id:"unhappy",
        
        en:"disappointed",
        
        },
        {
        id:"unpaid",
        
        en:"You already have an unpaid application",
        
        },
        {
        id:"upload",
        
        en:"Upload",
        
        },
        {
        id:"upTo",
        
        en:"Up to",
        
        },
        {
        id:"used",
        
        en:"Used",
        
        },
        {
        id:"user",
        
        en:"User",
        
        },
        {
        id:"users",
        
        en:"Users",
        
        },
        {
        id:"usersLimit",
        
        en:"Limit on the number of users",
        
        },
        {
        id:"validate",
        
        en:"Validationt",
        
        },
        {
        id:"validityPeriod",
        
        en:"Validity period",
        
        },
        {
        id:"var_1",
        
        en:"card number",
        
        },
        {
        id:"var_10",
        
        en:"six monts",
        
        },
        {
        id:"var_11",
        
        en:"Montly",
        
        },
        {
        id:"var_12",
        
        en:"Maximum Savings",
        
        },
        {
        id:"var_13",
        
        en:"For a short time",
        
        },
        {
        id:"var_14",
        
        en:"Not paid",
        
        },
        {
        id:"var_15",
        
        en:"Unpaid",
        
        },
        {
        id:"var_16",
        
        en:"All fields must be filled",
        
        },
        {
        id:"var_17",
        
        en:"Payment",
        
        },
        {
        id:"var_18",
        
        en:"Paid",
        
        },
        {
        id:"var_19",
        
        en:"balance",
        
        },
        {
        id:"var_2",
        
        en:"All your data will be deleted. You will not be able to view and edit the scripts you have created. Account recovery is not possible.",
        
        },
        {
        id:"var_20",
        
        en:"Payment frequency",
        
        },
        {
        id:"var_21",
        
        en:"Annually Subscription",
        
        },
        {
        id:"var_22",
        
        en:"Subscription for six months",
        
        },
        {
        id:"var_23",
        
        en:"more",
        
        },
        {
        id:"var_24",
        
        en:"price",
        
        },
        {
        id:"var_25",
        
        en:"View in Browser",
        
        },
        {
        id:"var_26",
        
        en:"Passed",
        
        },
        {
        id:"var_27",
        
        en:"Listen",
        
        },
        {
        id:"var_28",
        
        en:"annually",
        
        },
        {
        id:"var_29",
        
        en:"download invoice",
        
        },
        {
        id:"You (30 words max.)",
        
        en:"options",
        
        },
        {
        id:"var_30",
        
        en:"Thank you",
        
        },
        {
        id:"var_31",
        
        en:"payments detail",
        
        },
        {
        id:"var_32",
        
        en:"invoice",
        
        },
        {
        id:"var_33",
        
        en:"Plan",
        
        },
        {
        id:"var_34",
        
        en:"Plans",
        
        },
        {
        id:"var_35",
        
        en:"Delete account",
        
        },
        {
        id:"var_36",
        
        en:"Delete account",
        
        },
        {
        id:"var_37",
        
        en:"Done",
        
        },
        {
        id:"var_38",
        
        en:"To study carefully",
        
        },
        {
        id:"var_39",
        
        en:"Emotion",
        
        },
        {
        id:"var_4",
        
        en:"Action",
        
        },
        {
        id:"var_5",
        
        en:"all orders",
        
        },
        {
        id:"var_6",
        
        en:"Annually",
        
        },
        {
        id:"var_7",
        
        en:"Order summary",
        
        },
        {
        id:"var_8",
        
        en:"Order created",
        
        },
        {
        id:"var_9",
        
        en:"For payment",
        
        },
        {
        id:"variableWords",
        
        en:"Variable words",
        
        },
        {
        id:"voiceOver",
        
        en:"Voiceover",
        
        },
        {
        id:"voicing",
        
        en:"Voiceover",
        
        },
        {
        id:"wholeWords",
        
        en:"Whole words only",
        
        },
        {
        id:"year",
        
        en:"year",
        
        },
        {
        id:"yearly",
        
        en:"Annualy",
        
        },
        {
        id:"yes",
        
        en:"Yes",
        
        },
        {
        id:"you",
        
        en:"You",
        
        },
        {
        id:"yourSavings",
        
        en:"Your savings",
        
        },
        {
        id:"youWarn",
        
        en:"You (30 words limit)",
        
        },
        {
        id:"ySubs",
        
        en:"Annual subscription",
        
        },
        {
        id:"CaseSensitive",
        
        en:"Case sensitive",
        
        },
        {
        id:"isFinal",
        
        en:"Is the final",
        
        },
        {
        id:"studentNoAdded",

        en:"The student is already in the system",

        },
        {
            id:"resultScore",

            en:"Score",

            },
             {
            id:"finishText",

            en:"Pass score",

            },
             {
            id:"created",

            en:"Created",

            },
              {
            id:"createNew",

            en:"Create",

            },
               {
            id:"instructions",

            en:"Getting started",

            },
             {
            id:"completedSuccessfully_1",

            en:"Completed",

            },
             {
            id:"totalLearningTime",

            en:"Total time",

            },
              {
            id:"min.",

            en:"min.",

            },
               {
            id:"learningDynamic",

            en:"Participation insight",

            },
             {
            id:"minuteByTariff",

            en:"Minutes by plan",

            },
              {
            id:"allMinuteByTariff",

            en:"Total for the plan",

            },
      
              {
            id:"usedMinuteByTariff",

            en:"Used",

            },    {
            id:"leftMinuteByTariff",

            en:"Left",

            },
            {
            id:"monthPopularScript",

            en:"Featured Script of the Month",

            },
            {
            id:"usedMin",

            en:"Minutes used",

            },
             {
            id:"activeStudent",

            en:"Active learners",

            },
             {
            id:"appointNum",

            en:"Enrollments",

            },
              {
            id:"appointSuccsNum",

            en:"Successful attempts",

            },
            
             {
            id:"learningsTime",

            en:"Practice time",

            },
              {
            id:"completed",

            en:"Completed",

            },
            {
            id:"lastAttemptScore",

            en:"Last try score",

            },
            {
            id:"view",

            en:"Resume",

            },
                {
            id:"attempt",

            en:"Attempt",

            },
            {
            id:"datе",

            en:"Date",

            },
                    {
            id:"attemptResult",

            en:"Last session score",

            },
                {
            id:"attemptScore",

            en:"Score",

            },
                    {
            id:"attemptLog",

            en:"Conversations",

            },
            {
            id:"taskCompleteScore",

            en:"Score",

            },
            {
            id:"dialogCount",

            en:"number of replicas",

            },
            {
            id:"notStarted",

            en:"Not started",

            },
             {
              id:"1",

              en:"January",

             },
             {
              id:"2",

              en:"February ",

             },
             {
              id:"3",

              en:"March ",

             },{
              id:"4",

              en:"April",

             },{
              id:"5",
 
              en:"May",

             },{
              id:"6",

              en:"June",

             },{
              id:"7",

              en:"July",

             },{
              id:"8",

              en:"August",

             },{
              id:"9",
  
              en:"September",
 
             },{
              id:"10",
 
              en:"October",

             },{
              id:"11",

              en:"November",
 
             } ,{
              id:"12",

              en:"December",
  
             }
             ,{
              id:"showAll1",

              en:"Show All",

             }
              ,{
                id:"faq",
  
                en:"FAQ",
 
               }
               ,{
                id:"examButton",

                en:"Start the exam",

                },
        

]


const langMethods={
    methods:{
        getLangConst(val){
            let located=store.getters.getLocated
             located='en'
            let curName=langCatalog.find(l=>l.id==val)

        return curName!=undefined?curName[located]:'not found lang Const!!'

        },
         getInitials(val){
            if(!val) return ""
            return val.split(" ").map((n)=>n[0]).join("").slice(0,2).toUpperCase()
          },
          strDateTime(date){
            if(!date){
                return ''
            }
           let tDate=new Date(date)
          
           return tDate.toLocaleString().substring(0, 10);
          // return tDate.getDate()+'.'+tDate.getMonth()+1 +'.'+tDate.getUTCFullYear()+' '+tDate.getHours()+':'+tDate.getMinutes()
        }
    }

}
        
export { langMethods };
  